import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import ThemeRoutes from './routes/';
import NoInternetConnection from './components/NoInternetConnection';
function App() {
  return (
    <HelmetProvider>
        <Helmet>
          {process.env.REACT_APP_NODE_ENV === 'production' && (
            <meta
              httpEquiv="Content-Security-Policy"
              content={`
                default-src 'self';
                script-src 'self';
                connect-src 'self';
                img-src 'self';
                media-src 'self';
                style-src 'self' 'unsafe-inline'; 
                font-src 'self' data:;
                base-uri 'self';
              `}
            />
          )}
        </Helmet>
        <BrowserRouter>
    <NoInternetConnection>
    <ThemeRoutes />

    </NoInternetConnection>
    </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
