import React from "react";
import {
  AppBar,
  Toolbar,
  Link,
  Typography,
  InputBase,
  Box,
  InputAdornment,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import ThemeToggle from "./ThemeToggle";

const SearchBar = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "25px",
  marginLeft: 2,
  marginRight: 2,
  width: "100%",
  backgroundColor: "transparent",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    backgroundColor:'rgba(0, 0, 0, 0.3)',
      // theme.palette.mode === "dark"
      //   ? 'rgba(0, 0, 0, 0.3)'
      //   : theme.palette.grey[300],
    borderRadius: "25px",
    fontSize:'14px',
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function Header() {
  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        bgcolor: "transparent",
        backgroundImage: "none",
        display: "flex",
        // alignItems: "center",
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* Brand Logo */}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "flex-start",
            px: 0,

          }}
        >
          <Link
            sx={{ flexShrink: 0, textDecoration: "none" }}
            component={NavLink}
            to="/"
            aria-label="home-page"
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ fontFamily: "cursive", flexGrow: 1, ml: 3 }}
            >
              haji
            </Typography>
          </Link>
        </Box>

        {/* Search Bar */}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "flex-start",
            px: 0,
          }}
        >
          <SearchBar id="searchbar">
            <StyledInputBase
              placeholder="Search…"
              endAdornment={
                <InputAdornment position="end" sx={{ ml: "-36px" }}>
                  <Search
                    fontSize="small"
                    sx={{
                      fontSize: "1.5rem",
                      color: "text.primary",
                     
                    }}
                  />
                </InputAdornment>
              }
            />
          </SearchBar>
        </Box>

        <ThemeToggle />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
