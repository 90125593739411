import React from "react";
import {  IconButton,  useTheme, Tooltip  } from '@mui/material';
// ==============================|| HEADER - CONTENT ||============================== //
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import {useDarkTheme} from '../Context/ThemeContext'

const ThemeToggle = () => {

  const {toggleDarkMode, mode} = useDarkTheme();
  // eslint-disable-next-line
  const theme = useTheme();


  return (
    <>
      
        <Tooltip title="Toggle theme">
      <IconButton 
      aria-label='theme-toggle'
      sx={{ 
        // ml: 1 
      }} 
        onClick={toggleDarkMode} >
        {mode === 'dark' ? <WbSunnyRoundedIcon /> : <ModeNightRoundedIcon />}
      </IconButton>
      </Tooltip>
      
     
    </>
  );
};

export default ThemeToggle;
