import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import { useTheme } from '@mui/material/styles';
// ==============================|| MINIMAL LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  return (
    <Box component="main" 
    sx={{ width: '100%', flexGrow: 1,
      p:'0px',
      zIndex:5,
      overflow:'hidden',
      bgcolor: theme.palette.background.default,
       }}>
  <Header />
    <Outlet />
  {/* <Footer /> */}
  </Box>

  )
}



export default MainLayout;
