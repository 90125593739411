import { lazy, Suspense } from 'react';
import MainLayout from '../components/MainLayout.js';
import { CircularProgress, Box } from "@mui/material";
// const MainLayout = lazy(() => import('../components/MainLayout'));
// Lazy load the components
// const About = lazy(() => import('../components/About'));
// const Contact = lazy(() => import('../components/Contact'));
// const Blog = lazy(() => import('../components/Blog'));
// const Services = lazy(() => import('../components/Services'));
const Home = lazy(() => import('../Pages/Home'));
// const Blog2023 = lazy(() => import('../components/Blog2023'));
// const BlogShould = lazy(() => import('../components/BlogShould'));
// const BlogWhy = lazy(() => import('../components/BlogWhy'));
// const BlogSocial = lazy(() => import('../components/BlogSocial'));
// const Privacy = lazy(() => import('../components/Privacy'));
// const Knowledge = lazy(() => import('../components/Knowledge'));
// const Terms = lazy(() => import('../components/Terms'));
// const ThreeScene = lazy(() => import('../components/Portofolio'));
// const ErrorPage = lazy(() => import('../components/ErrorPage'));
// const BlockPageContent = lazy(() => import('../components/BlogPageContent'));
// const POS = lazy(() => import('../components/POS'));
// const HMS = lazy(() => import('../components/HMS'));
// const PMS = lazy(() => import('../components/PMS'));

function Loader () {
  return (
    <Box
      sx={{
        display:'flex',
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: '100vh',
      }}
    >
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "47%",
          color: "primary",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Box>
  );
}
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: (
        <Suspense fallback={<Loader />}>
          <Home />
        </Suspense>
      )
    },
  ]

};

export default MainRoutes;
