import { alpha } from '@mui/material/styles';
import { lightBlue, blue, teal, grey, green, amber, red, pink } from '@mui/material/colors';

// ----------------------------------------------------------------------
const primaryColor = red; 

// SETUP COLORS
const GREY = {
  0: grey[0],
  100: grey[100],
  200: grey[200],
  300: grey[300],
  400: grey[400],
  500: grey[500],
  600: grey[600],
  700: grey[700],
  800: grey[800],
  900: grey[900],
};

const PRIMARY = {
  lighter: primaryColor[200],
  light: primaryColor[400],
  main: primaryColor[500],
  dark: primaryColor[700],
  darker: primaryColor[900],
  contrastText: '#fff',
};


const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: primaryColor[400],
  dark: primaryColor[600],
  darker: '#091A7A',
  contrastText: '#fff',
};

const INFO = {
  lighter: lightBlue[50],
  light: lightBlue[100],
  main: lightBlue[500],
  dark: lightBlue[700],
  darker: lightBlue[900],
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: green[50],
  light: green[100],
  main: green[500],
  dark: green[700],
  darker: green[900],
  contrastText: '#fff',
};

const WARNING = {
  lighter: amber[50],
  light: amber[100],
  main: amber[500],
  dark: amber[700],
  darker: amber[900],
  contrastText: '#fff',
};

const ERROR = {
  lighter: red[50],
  light: red[100],
  main: red[500],
  dark: red[700],
  darker: red[900],
  contrastText: '#fff',
};



// confirmed
// dueIn
// dueOut
// noShow
// checkouts
// cancelled
// total
const TOTAL = {
  main: pink[500] ,
  dark: pink[700] ,
  light: pink[200] ,
  contrastText: '#000',
  
  }
  const CHECKOUTS = {
    main: pink[500] ,
    dark: pink[700] ,
    light: pink[200] ,
    contrastText: '#000', 
    }
  const NOSHOW = {
    main: amber[500] ,
    dark: amber[700] ,
    light: amber[200] ,
    contrastText: '#000', 
}
  const DUEIN = {
  main: teal[500] ,
  dark: teal[700] ,
  light: teal[200] ,
  contrastText: '#000', 
  }
  
  const DUEOUT = {
    main: pink[500] ,
    dark: pink[700] ,
    light: pink[200] ,
    contrastText: '#000', 
    }
  const CHECKEDIN = {
  light: green[400],
  dark: green[700],
  main: green[500],
  contrastText: '#000',
  
  }

  
  
  const CONFRIMED = {
  main: blue[500],
  light:   blue[400],
  dark:  blue[700],
  contrastText: '#000',
  
  }
  
  const CANCELLED = {
  main: '#bdbdbd',
  light: '#bdbdbd',
  dark: '#bdbdbd',
  contrastText: '#000',
  
  }
const DARK_GREY = {
  0: '#fafafa',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
};



const MAINTAINANCE =   {// maintainance
  lighter: '#cc0066',
  light: '#bc1c60',
  main: '#6c1648',
  dark: '#961043',
  darker: '#061B64',
  contrastText: '#fff',

}

const VACANT = { // managed
  main: '#229A16',
  light: '#229A16',
  dark: '#229A16',
  contrastText: '#000',
  }
const SUCCESS_DARK = {
  lighter: '#223823', // Darker green for lighter shade
  light: '#1F8B15',   // Slightly darker shade of light
  main: '#43C42D',    // Main color, slightly adjusted for dark theme
  dark: '#1E8A15',    // Darker green for dark shade
  darker: '#064B0A',  // Much darker shade for contrast
  contrastText: '#FFFFFF', // Contrast text set to white for dark theme
};



const WARNING_DARK = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: DARK_GREY[800],
};



const LOGO = {
  light:'#6c1648',
  dark:'#f06292',
}



const LINK ={
  normal: '#007BFF', // adjust the color according to your preference
  hover: '#0056b3',
};





const DARK_PRIMARY = {
  lighter: primaryColor[50],
  light: primaryColor[100],
  main: primaryColor[300],
  dark:  primaryColor[700],
  darker: primaryColor[900],
  contrastText: '#fff',
};

const DARK_SECONDARY = {
  lighter:primaryColor[100],
  light: primaryColor[200],
  main: primaryColor[300],
  dark: primaryColor[600],
  darker: primaryColor[900],
  contrastText: '#fff',
};

const LIGHT_PRIMARY = {
  lighter: primaryColor[100],
  light: primaryColor[300],
  main: primaryColor[500],
  dark: primaryColor[700],
  darker:primaryColor[900],
  contrastText: '#fff',
};

const DARK_PALETTE = {
  grey: DARK_GREY,
  primary: DARK_PRIMARY,
  secondary: DARK_SECONDARY,
  divider: alpha(DARK_GREY[700], 0.24),
  text: {
    primary: '#fff',
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(255, 255, 255, 0.5)',
  },
  background: { 
    paper: '#424242',
    default:'#424242',
    transparent:'#00000000',
    neutral: DARK_GREY[700], // table header
    calendar:'#1A2027'

  },
  action: {
    active: DARK_GREY[300],
    selected:  DARK_GREY[400],
    disabled: alpha(DARK_GREY[500], 0.8),
    disabledBackground: alpha(DARK_GREY[500], 0.24),
    focus: alpha(DARK_GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  success: SUCCESS_DARK,
  warning: WARNING_DARK,
 
 


};

const LIGHT_GREY = {
  0: '#FFFFFF',
  // 100: 'rgba(255, 255, 255, 0.12)',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};



const LIGHT_SECONDARY = {
  lighter: '#B3E0F2',
  light: '#81D4FA',
  main: primaryColor[500],
  dark: primaryColor[200],
  darker: '#01579B',
  contrastText: '#fff',
};

const LIGHT_PALETTE = {
  grey: LIGHT_GREY,
  primary: LIGHT_PRIMARY,
  secondary: LIGHT_SECONDARY,
  divider: alpha(LIGHT_GREY[400], 0.24),
  text: {
    primary: '#000',
    secondary: 'rgba(0, 0, 0, 0.7)',
    disabled: 'rgba(0, 0, 0, 0.5)',
  },
  background: {
    paper: '#fff',
    default: '#fff',
    neutral: LIGHT_GREY[200], // table header
    transparent:'#00000000',
    calendar:LIGHT_GREY[400]
  },
  action: {
    active: LIGHT_GREY[500],
    selected: LIGHT_GREY[700],
    disabled: alpha(LIGHT_GREY[500], 0.8),
    disabledBackground: alpha(LIGHT_GREY[300], 0.24),
    focus: alpha(LIGHT_GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette =  {
  mode: "default" ? "light": "dark",
  common: { black: '#000', white: '#fff' },
  light:LIGHT_PALETTE,
  dark: DARK_PALETTE, // Add the dark palette
  // default:LIGHT_PALETTE,
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  link:LINK,
  success: SUCCESS,
  logo:LOGO,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  vacant: VACANT,
  maintainance: MAINTAINANCE,
  total: TOTAL,
  checkedIn: CHECKEDIN,
  confirmed:CONFRIMED,
  cancelled: CANCELLED,
  checkOuts: CHECKOUTS,
  checkIns: CHECKEDIN,
  dueOut: DUEOUT,
  dueIn: DUEIN,
  noShow: NOSHOW,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    // hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  
};

export default palette;
